<template>
  <div class="table-wrapper">
    <z-table
      class="mt-3"
      responsive
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :busy="loading"
      @row-clicked="goToOrderDetail"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <loading-spinner />
        </div>
      </template>
      <template v-slot:cell(status)="row">
        <status-reservation :status="row.item.status" />
      </template>
    </z-table>
    <z-table-pagination
      :total-rows="totalRows"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
  </div>
</template>

<script>
import { makeShortUUID } from "@/utils/strings";
import { formatMoney } from "@/utils/money";
import { getNextDate } from "@zubut/common/src/utils/time";
import { initialize } from "@/utils/array";
import { truncate } from "@/utils/strings";
import pagination from "@/mixins/pagination";
import ZTable from "@zubut/common/src/components/ZTable";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import StatusReservation from "@/app/components/StatusReservation";
import moment from "moment";
import currency from "currency.js";

export default {
  name: "HourRentReservations",

  components: {
    ZTable,
    ZTablePagination,
    StatusReservation
  },

  mixins: [pagination],

  data() {
    return {
      fields: [
        {
          key: "id",
          label: "Id de reservación",
          formatter: value => makeShortUUID(value)
        },
        {
          key: "origin",
          label: "Origen",
          sortable: true,
          formatter: value =>
            value && value.address ? truncate(value.address, 24) : ""
        },
        {
          key: "createdAt",
          label: "Fecha de creación",
          sortable: true,
          formatter: value => moment(value).format("MMMM DD, YYYY hh:mm a")
        },
        {
          key: "nextReservation",
          label: "Próxima reservación",
          formatter: value =>
            value == null ? "-" : moment(value).format("DD MMMM hh:mm a")
        },
        {
          key: "daysNo",
          label: "Días reservados",
          formatter: value => `${value} día${value > 1 ? "s" : ""}`
        },
        {
          key: "totalHours",
          label: "Total de horas",
          formatter: value =>
            value < 1 ? `${value.toFixed(1)} horas` : `${value} horas`
        },
        {
          key: "cost",
          label: "Costo",
          formatter: value => formatMoney(currency(value).multiply(1.16))
        },
        { key: "status", label: "Estado" }
      ],
      items: [],
      loading: true,
      placeholderItem: {
        id: "",
        createdAt: null,
        daysNo: 0,
        totalHours: 0,
        cost: 0,
        status: 0
      }
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        if (newRoute.query.tab && newRoute.query.tab === "reservaciones") {
          this.getReservations();
        }
      }
    },
    currentPage: "getReservations",
    perPage: "getReservations"
  },
  created() {
    this.getReservations();
  },
  methods: {
    getReservations() {
      this.loading = true;
      this.$store
        .dispatch("rents/getReservationListing")
        .then(({ reservations, meta }) => {
          if (meta.skip === 0 && meta.count && this.totalRows === 0) {
            this.totalRows = meta.count;
            this.items = initialize({
              value: this.placeholderItem,
              size: this.totalRows
            });
          }
          this.items = reservations.map(item => {
            item.status = Number.isInteger(item.status) ? item.status : 2;
            item.statusName = item.statusName || "Finalizada";
            item.nextReservation = getNextDate(
              item.reservationBlocks.map(_ => _.start)
            );
            return item;
          });
          this.items.splice(meta.skip, reservations.length, ...reservations);
        })
        .catch(err => {
          this.$captureError(err);
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goToOrderDetail(item) {
      this.$router.push({
        name: "rentDetail",
        params: { id: item.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  padding: 1.7rem;
  background-color: $white;
  border-radius: 1em;
  border: 1px solid $solitude;
}
</style>
